export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy ID"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Today at ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Yesterday at ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission to talk in this channel."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Connected"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach a message"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your message"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Bottom"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Messages"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compress"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload To"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Profile"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Roles"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy User:Tag"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark As Read"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Settings"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave Server"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute Channel"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmute Channel"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Emojis"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Options"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startup Options"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Activity"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Bots"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIP Features"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Your Profile"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-link Google Drive"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Google Drive"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Profile"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information is seen by everyone."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your notification settings."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Sound"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makes a cool sound to get your attention"])},
      "notification-mentions-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions/Direct Messages Only"])},
      "notification-mentions-only-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makes it so only mentions and direct messages will make sound and be pushed."])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push Notification"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get push notifications even when the app is closed."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your own pretty emojis for free! Emojis must be 3MB or less."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported file types are: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Emojis"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop App Startup Options"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To modify these settings, you must download the Nertivia desktop app."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Nertivia On Startup"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share programs or games you are running by adding apps below. This will replace your custom status when a program is opened and will revert back when the program is closed."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use the program acitivty feature, you must download the Nertivia desktop app."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create bot users which will allow you to use our API to create your own fancy bots."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add bot commands to easily let users know which commands exist for your bot"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Bot"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Command"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Bot"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Commands"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Invite Link"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Invite URL"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Token"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Token"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Token"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Token"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Bot"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to delete your account"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What will get deleted"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What won't get deleted"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your messages sent to public servers"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave/Delete all servers"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all bots"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete My Account"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload may be required after changing language. Contribute by submitting a PR in the GitHub repo."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translators"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the appearance of Nertivia."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colors"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Format"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Theme"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Dark"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-defined Themes"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom CSS"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation Bar"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable unfinished or experimental features."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No WIP Features available right now."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enables ALL permissions"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Messages"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send messages to server channels"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Roles"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update or delete roles"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Channels"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update or delete channels"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick a user"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick & restrict a user from joining"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Channels"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Roles"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Invites"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Visibility"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Emojis"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Notification"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned Users"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Users"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Server"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your roles."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Role"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Role"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Role"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This role cannot be deleted/assigned to another member as it was created by a bot. Remove the bot to remove this role."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This role cannot be deleted or be un-assigned as this is the default role that gets applied to every member."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Role Color"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Name"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Role"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting Role..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Role"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Server Settings"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your channels."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Channel"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting Channel..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Name"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a default channel. You may change the default channel in the General page. Default channels cannot be deleted."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Messages"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Limit (seconds)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Channel"])},
      "category-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML Channel (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unban members from here"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbanning..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unban"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only admins can see everyone's invites."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Invites"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Invite"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Custom Link"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Invite"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uses"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search User"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type server name to confirm"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change notification settings."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These settings will only apply to you and won't affect others."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Notifications"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute Sound"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable Notifications"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " is typing..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " and ", _interpolate(_list(1)), " are typing..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " and ", _interpolate(_list(2)), " are typing..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " people are typing..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recents"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Notes"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Friend"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add custom status"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Caught Up!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Messages"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servers"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a friend by typing their username and tag."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Server"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join a server by entering in an invite code."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your own server. Start by naming your server."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Away"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busy"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking To Play"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Members (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Offline (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common Friends"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badges"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joined"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Friend"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Request"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Request"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Friend"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common Servers"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticating..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Available"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["left the server"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been kicked"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been banned"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joined the server!"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not connect to server."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Limit Mode"])}
  }
}