
import Popouts from "@/components/popouts/Popouts.vue";

import {
  getCustomCssVars,
  changeCssVar,
  setThemeColor,
} from "@/utils/customCssVars";
import { applyFont } from "./utils/applyFont";
import fonts from "@/utils/fonts.json";
import { clear } from "idb-keyval";
import { defineAsyncComponent } from "vue";
import { defineComponent } from "vue";
import i18n from "./i18n";

const WindowControl = defineAsyncComponent(
  () => import("@/components/electron/WindowControl.vue")
);

export default defineComponent({
  name: "App",
  components: { WindowControl, Popouts },
  mounted() {
    if (!localStorage["hauthid"]) {
      clear();
    }
    // applyfont
    applyFont(localStorage["font"] || Object.values(fonts)[0].id);
    // set custom css colors
    const customVars = getCustomCssVars();
    for (let i = 0; i < Object.keys(customVars).length; i++) {
      const key = Object.keys(customVars)[i];
      const value = customVars[key];
      changeCssVar(key, value, false);
    }
    setThemeColor();
    this.setLocale();
  },
  methods: {
    setLocale() {
      const currentLocale = localStorage["locale"] || "en";
      if (currentLocale === "en") return;
      import(`@/locales/${currentLocale}.json`).then((messages) => {
        i18n.global.setLocaleMessage(currentLocale, messages.default);
        i18n.global.locale = currentLocale;
      });
    },
  },
});
